import React from 'react';

import Layout from '../../layout/ConsultantLayout';
import AuthenticationView from '../../views/consultant/authenticationView';

const Authentication = (): React.ReactElement => {
  return (
    <Layout>
      <AuthenticationView />
    </Layout>
  );
};

export default Authentication;
